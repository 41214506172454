import React, { useEffect, useState } from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { Form } from "skillstrainer-resource-library"
import RegisterFormBuilder from "../formBuilder/user-interest-form-builder"

export default function SponsorForm() {
  let price = 0
  const [totalPrice, setTotalPrice] = useState(0)
  const [couponData, setCouponData] = useState({})

  const fetchData = async () => {
    try {
      const result = await axios.get(`${process.env.URL}/api/coupons`)
      setCouponData(result.data.data)
    } catch (error) {
      console.log("Error:", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const setDiscount = (setPrice, coupon) => {
    const filteredData = couponData.filter(data => {
      if (data.attributes.coupon_code === coupon) {
        return data
      }
    })

    if (coupon && coupon === filteredData[0]?.attributes?.coupon_code) {
      const discountedPrice =
        setPrice - (setPrice * filteredData[0]?.attributes?.discount) / 100
      setTotalPrice({ price, discountedPrice })
    } else {
      setTotalPrice({ price, setPrice })
    }
  }
  console.log(totalPrice)
  const handleOnUpdate = update => {
    const { category, coupon } = update.values
    if (category === "category1") {
      price = 1000000
      setDiscount(price, coupon)
    } else if (category === "category2") {
      price = 500000
      setDiscount(price, coupon)
    } else if (category === "category3") {
      price = 200000
      setDiscount(price, coupon)
    }
  }

  console.log(price)

  const handleSubmit = async register => {
    const payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",
        coupon_code: register.coupon,
        data: {
          form_type: "sponsor",
          full_name: register.full_name,
          designation: register.designation,
          company_name: register.company_name,
          email: register.email,
          phone_number: register.phone_number,
          country: register.country,
          category: register.category,
        },
      },
    }

    try {
      const result = await axios.post(`${process.env.URL}/api/orders`, payload)

      const paymentURL = new URL(
        "http://esg-payment.skillstrainer.in/?payloadBase64="
      )

      const payLOadJson = JSON.stringify(payload)
      const payloadBase64 = window.btoa(payLOadJson)

      paymentURL.searchParams.set("payloadBase64", payloadBase64)
      window.open(paymentURL.href, "_self")
    } catch (err) {
      console.log("I am error", err)
    }
  }

  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <h1 className="heading mb-3">Register As a Sponsor</h1>
        <ToastContainer />

        <Form
          formBuilder={RegisterFormBuilder}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
          onUpdate={handleOnUpdate}
        />

        {totalPrice.price > 0 && (
          <div className="mt-5">
            Price ={" "}
            {`${totalPrice.price} + 18% GST = ${Math.round(
              totalPrice.discountedPrice
                ? totalPrice.discountedPrice * 1.18
                : totalPrice.price * 1.18
            )}`}
          </div>
        )}
      </div>
    </>
  )
}
